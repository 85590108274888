@tailwind base;
@tailwind components;
@tailwind utilities;
/* Add this to your CSS file */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


/* styles.css */
/* styles.css */
/* styles.css */


/* Add this CSS to your styles */
.square-image {
  width: 100%;  /* Set to 100% to make full use of the slide space */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Crop the image to fit */
}

.slick-slide {
  display: flex; /* Align items in center */
  justify-content: center; /* Center images horizontally */
  align-items: center; /* Center images vertically */
}

.slick-list {
  margin: 0 -10px; /* Adjust margin for padding */
}

.category-selector {
  padding: 1rem;
  overflow: hidden; /* Prevent overflow of items */
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-red-500 {
  border-color: #e53e3e; /* Replace with your theme color */
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



body {
  /* Remove the background image */
  background: linear-gradient(to bottom, #b47118, white); /* Gradient background */
  
  /* Ensure the gradient covers the whole page and does not repeat */
  background-repeat: no-repeat;
  background-size: 100% 100%; /* Make sure the gradient fills the viewport */
  
  height: 100vh; /* Ensure the background covers the entire viewport height */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-family: Arial, sans-serif; /* Optional: set a default font */
}

.rounded-navbar {
  border-radius: 15px !important;
  margin: 20px; /* Optional: Adds some margin to the top and bottom */
}

@media (max-width: 768px) {
  .rounded-navbar {
    border-radius: 0; /* Remove rounded corners on small screens for better fit */
  }
}

.navlink {
  color: #fff !important;
}

.btn-primary {
  background-color: #dc5526 !important;
  border-color: azure !important;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h4.text-right {
  text-align: right;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
